<template>
  <slim-cropper :options="slimOptions">
    <input type="file" :id="uuid" name="slim"/>
  </slim-cropper>
</template>

<script>
import { computed } from "vue";
import Slim from '@/assets/plugins/slim/slim.vue';
import ApiService from "@/core/services/api.service";
import { uuid } from "vue-uuid";
export default {
  name: "Slim",
  props: {
    label: {
      type: String,
      default: "圖片拖移至此，請上傳最佳尺寸 寬1600px * 高 800px，可上傳後裁切。"
    },
    accept: {
      type: String,
      default: "*"
    },
    modelValue: {
      default: ""
    },
    width: {
      type: Number,
      default: 1600
    },
    height: {
      type: Number,
      default: 800
    },
    ratio: {
      type: String,
      default: "1:1"
    },
    url: {
      type: String,
      default: ""
    },
    server: {
      type: Boolean,
      default: false
    },
    uuid: {
      type: String,
      default: function() {
        return uuid.v1();
      }
    }
  },
  components: {
    'slim-cropper': Slim
  },
  setup(props, { emit }) {

    const Model = computed({
      get: () => props.modelValue,
      set: val => emit("update:modelValue", val),
    });

    const Url = computed({
      get: () => props.url,
      set: val => emit("update:url", val),
    });

    const slimInit = function(slim) {
      // slim instance reference
      console.log(slim)
      // current slim data object and slim reference
      // console.log(data)
    }

    const slimService = function(formdata, progress, success, failure) {
      let Form = new FormData;
      Form.append('image',formdata.get('slim_output_0'));
      let time = 0;
      var loading = setInterval(function(){ progress(((time<=90)?(time = time+2):time),100)}, 100);
      ApiService.post("/Admin/v1/Image", Form).then(response => {
        if (response.status == 200) {
          Model.value = response.data.data.id;
          Url.value = response.data.data.url;
          progress(100,100);
          success('success');
        }else{
          failure(response.data.message);
        }
        clearInterval(loading);
      });
    }

    const slimOptions = {
      forceSize: {
        width: props.width,
        height: props.height,
      },
      label: props.label,
      ratio: props.ratio,
      didInit: slimInit,
      didLoad: function(file) {
        if (!props.server) {
          Model.value = file;
          // emit("update:modelValue", );
          // console.log(event.target.files[0]);
          let reader = new FileReader();  
          reader.readAsDataURL(file);
          reader.onload = function() {
            Url.value = this.result;
          };
        }
        return true;
      },
      didRemove: function() {
        Model.value = "";
        Url.value = "";
      },
    }

    if(props.server) {
      slimOptions.server = slimService;
      slimOptions.push = true;
    }

    return {
      Model,
      slimOptions,
      slimService
    }
  },
  watch: {
    modelValue: function(value) {
      if(!value) {
        this.Url = "";
      }
    }
  }
};
</script>